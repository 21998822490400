<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.8rem;">文件标题：</span>
					<el-input v-model="searchinput" placeholder="请输入" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
					<!-- <el-button type="success" @click="addnew">新建公文处理单</el-button> -->
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="200" @getdata="gettable" @changswitch="changswitch">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="showdetail(scope.row)" type="primary" size="mini">详情</el-button>
							<el-button @click="showjslist(scope.row)" type="success" size="mini">查看接收人员</el-button>
						</template>
					</MyTable>
				</div>
			</div>
		</div>		
		
		<detail ref="mydetail"></detail>
		
		
		<el-dialog
		  title="接收人员"
		  :visible.sync="show"
		  width="50%">
		  <div style="width: 100%;height: 60vh;overflow-y:auto;padding: 20px;font-size: 16px;">
			  <div style="margin-bottom: 15px;font-size: 18px;">
				  文件标题：{{jslist[0]?jslist[0].matterfwen_name:''}}
			  </div>
			  <div style="margin-bottom: 10px;">抄送人数<span style="color:#277bf5">({{jslist.length}}人)</span></div>
			  
			  <div style="width:100%;">
			  	<div style="width: 100%;padding: 10px 0;border-bottom: 1px solid #eee;" v-for="item in jslist">
					<div style="display: flex;align-items: center;justify-content: space-between;width: 100%;">
						<div style="color: #333;font-size:16px;font-weight: bold;">
							{{item.user_name?item.user_name:''}}
						</div>
						<div style="font-size: 14px;">
							<span style="margin-right: 10px;">状态：</span>
							<el-tag v-show="item.type==1" type="info">待接收</el-tag>
							<el-tag v-show="item.type==2" type="">已接收</el-tag>
						</div>
					</div>
			  	</div>
			  </div>
		  </div>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="show = false">取 消</el-button>
		    <el-button type="primary" @click="show = false">确 定</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	import detail from '../components/detail.vue'
	import selectusers from '@/components/select_users.vue'
	export default {
		components:{ detail,selectusers },
		data() {
			return {
				searchinput: '',
				tableHeader: [{
						prop: 'id',
						name: '序',
						width: '50'
					},
					{
						prop: 'name',
						name: '文件标题',
						width: ''
					},
					{
						prop: 'fhuser_name',
						name: '复核人',
						width: '120'
					},
					{
						prop: 'qfuser_name',
						name: '签发人',
						width: '120'						
					},
					{
						prop: 'jjcd',
						name: '加急程度',
						width: '120',
						type:'jjcd'
					},
					{
						prop: 'addtime',
						name: '提交时间',
						width: '150'
					},
					{
						prop: 'type',
						name: '待接收状态',
						width: '150',
						type:'jstype'
					}
				],
				tableData: [],
				show:false,
				jslist:[]
			}
		},
		mounted() {
			this.gettable()
		},
		methods: {		
			showjslist(row){
				this.$post({
					url: '/api/matterfwen/ckqbchaosong',
					params: {
						matterfwen_id:row.id
					}
				}).then((res) => {
					this.jslist = res.list
					this.show = true
				})
			},
			showdetail(row){
				this.$post({
					url: '/api/matterfwen/details',
					params: {
						id:row.id
					}
				}).then((res) => {
					this.$refs.mydetail.info = res
					this.$refs.mydetail.dialogVisible = true
				})
			},
			postdata(row) {
				this.$confirm('您确定接收吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post({
						url: '/api/matter/cundang',
						params: {
							id:row.id
						}
					}).then((res) => {
						this.$message.success('操作成功')
						this.gettable()
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				})
			},
			showeidt(row) {
				this.$router.push('/system/get_file/edit?id=' + row.id)
			},
			changswitch(row) {
				this.$post({
					url: '/api/room/edit',
					params: row
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
				})
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/matterfwen/index',
					params: {
						name: this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize,
						type: '10,11'
					}
				}).then((res) => {
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			addnew() {
				this.$router.push('/system/get_file/add')
			},
			showdate(row) {
				this.$refs.myroomdate.dialogVisible = true
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import 'yichaos.scss';
</style>